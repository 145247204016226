
  
  import { gmapApi } from 'vue2-google-maps';
  
  import * as geolib from 'geolib';
  
  
  export default {
    data() {
      return {
        terima: false,
  
        locationAccuracy: null, // Properti untuk menyimpan akurasi lokasi
  
        
  
        markerOptions: {
          animation: null, // Properti untuk mengontrol animasi marker
        },
  
        peta: true,
        hutan: true,
  
  
        infoWindowPos: '', // Infowindow position
        infoOptions: {
          content: '',
          //optional: offset infowindow so it visually sits nicely on top of our marker
          pixelOffset: {
            width: 0,
            height: -35
          }
        },
        infoWinOpen: false,
        currentMidx: null,
  
        mdl_lihat: false,
        mdl_cek: false,
        mdl_hapus: false,
        
  
        clusterOptions: {
          gridSize: 30,
          maxZoom: 15,
        },
  
  
        //////////////////////////ZONA//////////////////////////////
        //     polygons_zona: [
        //     {
        //       paths: [
        //         { lat: -4.333731, lng: 122.274770 },
        //         { lat: -4.328698, lng: 122.279753 },
        //         { lat: -4.339085, lng: 122.293835 },
        //         { lat: -4.345920, lng: 122.285279 },
        //         // Koordinat vertex poligon pertama
        //       ],
        //     },
        //     {
        //       paths: [
        //         { lat: -4.356667, lng: 122.279725 },
        //         { lat: -4.353998, lng: 122.266840 },
        //         { lat: -4.342981, lng: 122.268637 },
        //         { lat: -4.346094, lng: 122.287058 },
        //         // Koordinat vertex poligon kedua
        //       ],
        //     },
  
        //     },
        //     // Tambahkan poligon tambahan di sini
        //   ],
  
  
        polygonHutanOptions: {
          fillColor: 'green',
          fillOpacity: 0.5,
          strokeOpacity: 1,
          strokeWeight: 1,
          // Opsi warna dan kecerahan arsiran polygon
        },
        polygonZonaOptions: {
          fillColor: 'blue',
          fillOpacity: 0.7,
          strokeOpacity: 1,
          strokeWeight: 1,
          // Opsi warna dan kecerahan arsiran polygon
        },
        polygonZonaOptionsModal: {
          fillColor: 'blue',
          fillOpacity: 0.3,
          strokeOpacity: 1,
          strokeWeight: 1,
          // Opsi warna dan kecerahan arsiran polygon
        },
  
        markers_zona: [],
        markerIcon: {
          url: 'img/avatar.png',
          scaledSize: { width: 40, height: 40 },
        },
  
  
        ///////////////////////END ZONA/////////////////////////////////
  
        final: {
          catatan: '',
          file: ''
        },
  
  
        form: {
          kecamatan: '',
          des_kel_id: ''
        },
        kecamatan: [],
        desa: [],
        lihat: [],
        cek_load_data: false,
  
        //
  
        no: 0,
        id: '',
        url: {
          GET_URL: this.$store.state.url.URL_APP + "api/v1/petafinal/"
        },
  
        id_user: '',
        status_user: null,
        id_kecamatan: '',
  
        petafinal: [],
        cari_value: "",
        hoveredPolygon: null,
  
        hasil: {
          id: '',
  
          nama_desa: '',
          nama_kecamatan: '',
          kode_kecamatan: '',
          kode_desa: '',
          coordinat: [],
          catatan: '',
          file: '',
          createAt: '',
          status_peta: '',
          createdBy: ''
        },
  
       
  
        file_old: "",
        file_path: this.$store.state.url.URL_APP + "uploads/"
  
      }
    },
    methods: {
      handlePolygonClick: function(data){
  
        this.Notify_marker(`Zona ini adalah Desa ${data.nama_desa}, Kecamatan ${data.nama_kecamatan} `);
  
      },
  
      handleHutanClick: function(data){
  
        this.Notify_marker(`Zona ini adalah Hutan ${data.fungsi}, Desa ${data.nama_desa} (Kecamatan ${data.nama_kecamatan})`);
  
      },
  
  
      /////////////////////////////////////////////NEW PETA DASAR////////////////////////////////////////////
      mapping: function(data) {
  
            var tampung = [];

            for(var i = 0; i < data.length; i++) {
                var obj = data[i];

                tampung.push({"lat":parseFloat(obj.lat),"lng":parseFloat(obj.lng)});
            }

            return tampung;

            },

      calculateArea(data) {
  
        var area = geolib.getAreaOfPolygon(data);
        return (area / 1000000).toFixed(2);
  
      },

      cetak() {
        this.$htmlToPaper('printMe');
      },
  
      onMarkerClick(marker, idx) {
        // Handle marker click event
        // console.log(marker);
        this.infoWindowPos = { lat: marker.lat, lng: marker.lng };
        // this.infoOptions.content = `latitude : ${marker.lat}, longitude: ${marker.lng}`;;
        this.infoOptions.content = `latitude : ${marker.lat}, longitude: ${marker.lng}  <br/><p><a href="https://www.google.com/maps/place/${marker.lat},${marker.lng}/@${marker.lat},${marker.lng}z/data=!3m1!4b1" target="_blank">Cek Lokasi!</a> </p>`;
        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        }
        //if different marker set infowindow to open and reset current marker index
        else {
          this.infoWinOpen = true;
          this.currentMidx = idx;
  
        }
      },
  
  
      calculatePolygonCentroid(data) {
  
        
        const coordinates = data.map((point) => ({
          latitude: point.lat,
          longitude: point.lng,
        }));
  
        const centroid = geolib.getCenter(coordinates);
  
        var hasil = {
          lat: centroid.latitude,
          lng: centroid.longitude,
        };
        
        return hasil;
      },
  
  
      getPetafinal: function (id_kecamatan) {
        this.cek_load_data = true;
  
  
        var kecamatan_id = '';
  
        if (this.form.kecamatan.id == null || this.form.kecamatan.id == undefined || this.form.kecamatan.id == '') {
          kecamatan_id = id_kecamatan;
        } else {
          kecamatan_id = this.form.kecamatan.id;
        }
  
        var des_kel_id = this.form.des_kel_id.id;
  
        fetch(this.url.GET_URL + "petafinal", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            kecamatan_id: kecamatan_id,
            des_kel_id: des_kel_id,
            status_user: this.status_user
          })
        })
          .then(res => res.json())
          .then(res_data => {
  
            //   console.log("peta ",res_data);
            
              this.petafinal = res_data;
            
            // 
  
  
          });
      },
  
  
  
      cek: function () {
        this.cek_load_data = true;
  
        fetch(this.url.GET_URL + "lihat", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            kecamatan_id: this.hasil.kode_kecamatan,
            des_kel_id: this.hasil.kode_desa
          })
        })
          .then(res => res.json())
          .then(res_data => {
  
            this.lihat = res_data;
            this.cek_load_data = false;
  
            // console.log("asdasdasd ",this.lihat)
            // 
  
  
          });
      },
  
  
      selectDataPetaFinal: function (data) {
  
  
        this.hasil = {
          id: data.id,
          nama_desa: data.nama_desa,
          nama_kecamatan: data.nama_kecamatan,
          kode_kecamatan: data.kode_kecamatan,
          kode_desa: data.kode_desa,
          coordinat: data.coordinat,
          catatan: data.catatan,
          file: data.file,
          createAt: data.createAt,
          status_peta: data.status_peta,
          createdBy: data.createdBy
  
        };
  
        this.file_old = data.file;
  
  
      },
  
  
  
      getKecamatan: function () {
        this.cek_load_data = true;
  
        fetch(this.url.GET_URL + "kecamatan", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            kecamatan_id: this.id_kecamatan,
            status_user: this.status_user
  
          })
        })
          .then(res => res.json())
          .then(res_data => {
  
            var tampung = [];
  
            var kode = '';
  
            for (var i = 0; i < res_data.length; i++) {
  
              if (res_data[i].hasil.kode < 10) {
                kode = `0${res_data[i].hasil.kode}`;
              }
              else {
                kode = `${res_data[i].hasil.kode}`
              }
  
  
              tampung.push({
                // kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.0${res_data[i].hasil.kode}`, 
                kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.${kode}`,
                nama_kecamatan: res_data[i].hasil.uraian
              })
            }
  
  
            this.kecamatan = tampung;
  
  
            this.cek_load_data = false;
          });
      },
  
      getDesa: function () {
        this.cek_load_data = true;
  
  
        fetch(this.url.GET_URL + "desa", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            kecamatan_id: this.form.kecamatan.id,
          })
  
        })
          .then(res => res.json())
          .then(res_data => {
  
  
            var tampung = [];
  
            var no_kec = '';
  
  
            for (var i = 0; i < res_data.length; i++) {
  
              if (res_data[i].no_kec < 10) {
                no_kec = `0${res_data[i].no_kec}`;
              }
              else {
                no_kec = `${res_data[i].no_kec}`
              }
  
  
              tampung.push({
                // kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.0${res_data[i].hasil.no_kec}`, 
                des_kel_id: `${res_data[i].no_prop}.0${res_data[i].no_kab}.${no_kec}.${res_data[i].kode}`,
                nama_des_kel: res_data[i].uraian
              })
            }
  
            // Tambah Ranowila
            if (this.form.kecamatan.id == '74.05.18') {
              tampung.push({
                // kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.0${res_data[i].hasil.no_kec}`, 
                des_kel_id: '74.05.18.2006',
                nama_des_kel: 'RANOWILA'
              })
            }
            if (this.form.kecamatan.id == '74.05.07') {
  
              // Remove Ranowila
              tampung.splice(16, 1);
  
            }
  
  
            this.desa = tampung;
  
            this.cek_load_data = false;
  
          });
      },

      removeData : function(E){

        console.log("asdasdas ",this.hasil.id);

        fetch(this.url.GET_URL + "removeData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({id : this.hasil.id})
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.mdl_cek = false;
            this.getPetafinal();
        });
  
      },
  
  
      /////////////////////////////////////////////END NEW PETA DASAR////////////////////////////////////////////
  
      onFileSelected: function (event) {
        this.final.file = event.target.files[0];
  
      },
  
  
  
      cari_data: function () {
  
        this.getDesa();
        this.getPetafinal();
  
      },
  
  
      Notify: function (message) {
        this.$q.notify({
          progress: true,
          message: message,
          color: 'success',
          avatar: 'https://cdn.quasar.dev/img/boy-avatar.png',
          position: 'top',
          actions: [
            { label: 'Keluar', color: 'yellow', handler: () => { /* ... */ } }
          ],
          timeout: 10000,
        })
      },
  
      Notify_marker : function(message){
            this.$q.notify({
              progress: true,
              message: message,
              color: 'success',
              avatar: 'https://cdn.quasar.dev/img/boy-avatar.png',
              position : 'top',
              actions: [
                { label: 'Keluar', color: 'yellow', handler: () => { /* ... */ } }
              ],
              timeout: 10000,
            })
          },
  
    
  
    },
  
    computed: {
      google: gmapApi
  
    },
    mounted() {
      var get_profile = JSON.parse(localStorage.profile);
  
      this.id_user = get_profile.id;
      this.status_user = get_profile.profile.status;
      this.id_kecamatan = get_profile.profile.id_kecamatan;

  
      this.getKecamatan();
      this.getPetafinal(this.id_kecamatan);
  
    },
    
  }
  